<template>
	<div>
		<base-dialog
			:show="!!error && !isLoading"
			title="An error occured"
			@close="error = null"
		>
			<p>{{ error }}</p>
		</base-dialog>
		<base-dialog fixed :show="isLoading" title="Authenticating...">
			<base-spinner></base-spinner>
		</base-dialog>
		<base-card>
			<form @submit.prevent="submitForm">
				<div class="form-control">
					<label for="email">E-Mail</label>
					<input type="email" id="email" v-model.trim="email" />
				</div>

				<div class="form-control">
					<label for="password">Password</label>
					<input
						type="password"
						id="password"
						v-model.trim="password"
					/>
				</div>

				<p v-if="!formIsValid">
					Please enter a valid email and password (must be at least 6
					characters long).
				</p>

				<base-button>{{ submitButtonCaption }}</base-button>
				<base-button
					type="button"
					mode="flat"
					@click="switchAuthMode"
					>{{ switchModeButtonCaption }}</base-button
				>
			</form>
		</base-card>
	</div>
</template>

<script>
	import BaseDialog from "../../components/ui/BaseDialog.vue";
	export default {
		components: { BaseDialog },
		data() {
			return {
				email: "",
				password: "",
				formIsValid: true,
				mode: "login",
				isLoading: false,
				error: null,
			};
		},
		computed: {
			submitButtonCaption() {
				return this.mode === "login" ? "Login" : "Signup";
			},
			switchModeButtonCaption() {
				return this.mode === "login"
					? "Signup instead"
					: "Login instead";
			},
		},
		methods: {
			async submitForm() {
				this.formIsValid = true;

				// Basic form validation
				if (
					this.email === "" ||
					!this.email.includes("@") ||
					this.password.length < 6
				) {
					this.formIsValid = false;
					return;
				}

				this.isLoading = true;

				try {
					await this.$store.dispatch(this.mode, {
						email: this.email,
						password: this.password,
					});
                    const redirectUrl = this.$route.query.redirect ?? '/coaches';
                    this.$router.replace(redirectUrl);
				} catch (e) {
                    console.log(e);
					this.error = e.message ?? "Failed to sign up. Try later";
				}

				this.isLoading = false;
			},
			switchAuthMode() {
				this.mode === "login"
					? (this.mode = "signup")
					: (this.mode = "login");
			},
		},
	};
</script>

<style scoped>
	h2 {
		text-align: center;
	}
	form {
		margin: 1rem;
		padding: 1rem;
	}

	.form-control {
		margin: 0.5rem 0;
	}

	label {
		font-weight: bold;
		margin-bottom: 0.5rem;
		display: block;
	}

	input,
	textarea {
		display: block;
		width: 100%;
		font: inherit;
		border: 1px solid #ccc;
		padding: 0.15rem;
	}

	input:focus,
	textarea:focus {
		border-color: #3d008d;
		background-color: #faf6ff;
		outline: none;
	}
</style>
